@import "../color";
@import "../mediaquery";

.recommendations {
  padding: 16px !important;
  background-color: $primary_color;

  .recommendationsTitle {
    text-align: center;
    color: white;
    // background-color: pink !important;

    .recommendationsHeading {
      padding: 0px 0px 8px 0px !important;
    }

    .recommendationsBanner {
      padding: 0px 0px 16px 0px !important;
    }
  }

  .recommendationsBanner {
    // background-color: pink;
  }

  .recommendationsSlider {
    padding: 16px 0px;

    .slick-list {
      padding: 0px 0px 0px 0px !important;
    }
  }

  .destinationImageContainer {
    position: relative;
    transition: all 500ms;

    .destinationName {
      position: absolute;
      bottom: 10px;
      right: 10%;
      font-size: 16px;
      color: #ffffff;
      content: "";
      background-color: rgba(0, 102, 166, 0.5);
      padding: 3px 15px;
      font-family: $primary-fontfamily;
    }
  }

  .destinationImageContainer img {
    width: 100%;
    transition: all 500ms;
  }
  .destinationImageContainer img:hover {
    -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.75);
    transform: scale(1.05);
  }

  .destinationImageContainer:hover {
    transform: scale(1.05);
  }

  .slick-prev {
    left: 10px;
    width: 50px;
    height: 50px;
    z-index: 10;
  }

  .slick-next {
    right: 10px;
    width: 50px;
    height: 50px;
  }

  .slick-prev::before,
  .slick-next::before {
    font-family: "slick";
    font-size: 30px;
    line-height: 1;
    opacity: 0.75;
    color: white;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .slick-dots {
    bottom: -10px !important;
    opacity: 1;
    width: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0px !important;
  }

  .slick-dots li button:before {
    font-size: 12px;
    // color: red;
  }
}

@media (min-width: 600px) {
  .recommendations {
    padding: 24px 24px !important;

    .slick-dots {
      bottom: -24px !important;
      opacity: 1;
      width: 100% !important;
      display: flex;
      justify-content: center;
      align-items: center;
      left: 0px !important;
    }
  }

  .recommendationsSlider {
    padding: 0px !important;
    padding: 0px 0px 8px 0px !important;
  }

  .destinationImageContainer {
    padding: 0px 8px;
  }
}

@media (min-width: 1024px) {
  .recommendations {
    padding: 48px 48px !important;

    .recommendationsSlider {
      .slick-list {
        padding: 10px !important;
      }
    }

    .slick-dots {
      margin-bottom: -10px;
    }
  }

  .destinationImageContainer {
    padding: 0px 12px;
    h5 {
      margin-top: 16px !important;
    }
  }
}

// @media (min-width: 1024px) {
//   .recommendations {
//     padding: 64px 48px !important;

//     .slick-prev {
//       left: 24px;
//       width: 50px;
//       height: 50px;
//       z-index: 10;
//     }

//     .slick-next {
//       right: 24px;
//       width: 50px;
//       height: 50px;
//     }

//     .slick-prev::before,
//     .slick-next::before {
//       font-family: "slick";
//       font-size: 35px !important;
//       line-height: 1;
//       opacity: 0.75;
//       color: white;
//       -webkit-font-smoothing: antialiased;
//       -moz-osx-font-smoothing: grayscale;
//     }

//     .slick-dots {
//       bottom: -40px;
//       opacity: 1;
//       width: 150px;
//       left: calc(50% - 75px);
//     }

//     .slick-dots li button:before {
//       font-size: 12px;
//       color: red;
//     }

//     display: flex;
//     flex-direction: column !important;
//     justify-content: center;
//     align-items: center;
//   }

//   .recommendationsBanner {
//     margin-bottom: 32px;
//   }

//   .destinationImageContainer {
//     padding: 0px 12px;
//   }
// }
