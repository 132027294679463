// .whyChooseUsSection {
//   //   border: 3px solid red;
//   padding: 24px 16px 0px 16px;
// }

// .whyChooseUsPortraitContainer {
//   //   background-color: lightblue;
// }

// .whyChooseUsPortrait {
//   width: 100%;
//   aspect-ratio: 16 / 9;
//   position: relative;
//   border: 10px solid #c5c5c5;
//   background-size: cover;
//   padding: 0px !important;

//   img {
//     width: 100% !important;
//   }
// }

// .whyChooseUsTitleS {
//   position: absolute;
//   top: 5%;
//   right: 7%;
//   color: white !important;
//   // background-color: red !important;
// }

// .whyChooseUsDescriptionSection {
//   margin-top: 8px !important;
// }

// // .whyChooseUsTitleSection {
// //   display: none !important;
// // }

// .whyChooseUsMessage {
//   text-align: justify;
//   margin-top: 16px;
// }

// .whyChooseUsDataSection {
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }

// .whyChooseUsDataGrid {
//   display: flex;
//   justify-content: space-evenly;
//   align-items: center;
//   padding: 32px 0px;
// }

// .whyChooseUsDataItem {
//   width: 30%;
//   //   border: 2px solid blue;
//   display: flex;
//   flex-direction: column !important;
//   justify-content: center;
//   align-items: center;
// }

// .whyChooseUsIcon {
//   color: $primary-color;
//   width: 50px !important;
//   height: 50px !important;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }

// .whyChooseUsDataItem h1 {
//   color: $primary-color;
// }

// .whyChooseUsDataItem h3 {
//   font-family: $secondary-fontfamily;
// }

// .whyChooseUsSection{
//   border:2px solid red;
// }

// .whyChooseUs{
//   border:5px solid green;
// }

// .whyChooseUsTitle{
//   color:$next_color !important;
// }

// .whyChooseUsReadMore{
//   display:flex;
//   justify-content:center;
//   align-items:center;
//   padding-top:16px;
// }

// @media (min-width: 600px) {
//   .whyChooseUsSection {
//     padding: 32px 0px;
//   }

//   .whyChooseUsDescriptionSection {
//     margin-top: 16px !important;
//   }
// }

// @media (min-width: 800px) {
//   .whyChooseUsSection {
//     display: flex;
//     flex-direction: row !important;
//     justify-content: space-between !important;
//     align-items: stretch !important;
//     padding: 32px 0px 32px 0px !important;
//   }

//   .whyChooseUsTitleS {
//     display: none !important;
//   }

//   .whyChooseUsPortraitContainer {
//     display: flex;
//     justify-content: flex-start;
//     align-items: stretch;
//     padding: 0px !important;
//   }

//   .whyChooseUsPortrait {
//     width: 100%;
//     aspect-ratio: 1/1;
//   }

//   .whyChooseUsDescriptionSection {
//     margin-top: 0px !important;
//     padding: 0px 0px 16px 16px !important;
//     width: 100% !important;
//     display: flex;
//     flex-direction: column !important;
//   }

//   .whyChooseUsTitleSection {
//     display: flex !important;
//     justify-content: center !important;
//     align-items: flex-start !important;
//     h1 {
//       color: $next_color !important;
//     }
//   }

//   .whyChooseUsReadMore {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//   }
// }

// @media (min-width: 1024px) {
//   .whyChooseUsSection {
//     display: flex;
//     flex-direction: column;
//     justify-content: flex-start;
//     align-items: flex-start;
//     padding: 64px 0px !important;
//     margin-left: 0px !important;
//   }

//   .whyChooseUsDataItem {
//     h1 {
//       color: $bg_color;
//     }
//   }

//   .whyChooseUsDataSection {
//     background-color: $next_color;
//     color: white !important;
//   }
//   .whyChooseUsIcon {
//     color: white !important;
//   }

//   .whyChooseUsDescriptionSection {
//   }
// }

// .whyChooseUsSection {
//   padding: 16px !important;
// }

// .whyChooseUsTitleS {
// }

// .whyChooseUsTitleM {
//   display: none !important;
// }

// @media (min-width: $sm) {

//   .whyChooseUsTitleS{
//     margin:auto !important;
//     text-align:center;
//     display:flex;
//     align-items:center;
//     justify-content:center;
//   }

//   .whyChooseDescSlider {
//     grid-gap: 16px !important;

//     img {
//       height: auto !important;
//     }
//   }

//   .whyChooseUsSlider{
//     padding:5% 10%;
//   }
// }

// @media (min-width: 800px) {
//   .whyChooseUsTitleS {
//     display: none !important;
//   }

//   .whyChooseUsTitleM {
//     display: flex !important;
//   }

//   .whyChooseUsSlider{
//     padding:0%;
//   }

//   .whyChooseDescSlider{
//     display: flex !important;
//     justify-content: center;
//     align-items: center;
//   }

// }

// @media (min-width: $lg) {
// }

.whyChooseUsTitle {
  color: $next-color;
  padding: 0px 16px;
}

.whyChooseUs {
  // padding: 16px;
  padding: 0px;
}

.whyChooseUsTitle,
.whyChooseSliderContainer,
.whyChooseUsDescription,
.whyChooseUsReadMore {
  padding: 0px 16px;
}

.whyChooseSliderContainer {
}

.whyChooseUsTitleS {
  display: none !important;
}

.whyChooseUsReadMore {
  align-self: flex-end;
  justify-self: flex-end;
}

.whyChooseUsDataGrid {
  margin-top: 16px !important;
}

.whyChooseUsDataSection {
  padding: 8px 16px !important;
  margin-top: 16px !important;
}

.whyChooseUsDataContainer {
  padding: 16px 16px !important;
  color: white !important;
}

@media (min-width: 600px) {
  .whyChooseUs {
    padding: 32px;
    padding: 0px;
  }

  .whyChooseUsTitle,
  .whyChooseUsDescription,
  .whyChooseUsReadMore {
    padding: 0px 32px;
  }

  .whyChooseSliderDesc {
    display: flex;
    flex-direction: row-reverse !important;
    justify-content: space-between !important;
  }

  .whyChooseSliderContainer {
    padding: 16px !important;
    width: 50% !important;
  }

  .whyChooseUsTitleS {
    display: flex !important;
  }

  .whyChooseUsTitleM {
    display: none !important;
  }

  .whyChooseUsTitleS,
  .whyChooseUsTitleM {
    color: $next-color;
  }

  .whyChooseUsDataSection {
    // border:2px solid blue !important;
    padding: 16px !important;
  }

  .whyChooseUsDataContainer {
    padding: 0px !important;
    padding: 16px 12% !important;

    h3 {
      padding: 0px !important;
    }
  }

  .whyChooseUsIcon {
    font-size: 32px !important;
  }
}

@media (min-width: 800px) {
  .whyChooseUs {
    padding: 32px;
    padding: 0px;
  }

  .whyChooseUsTitle,
  .whyChooseUsDescription,
  .whyChooseUsReadMore {
    padding: 0px 32px;
  }

  .whyChooseSliderDesc {
    display: flex;
  }

  .whyChooseSliderContainer {
    padding: 5% 10%;
  }
}

@media (min-width: 1024px) {
  .whyChooseUs {
    padding: 32px 48px !important;
    padding: 0px !important;
  }

  .whyChooseTitleDesc {
    padding-right: 16px !important;
    padding: 0px $tab-padding !important;
  }

  .whyChooseSliderContainer {
    width: 25% !important;
    max-width: 50% !important;
  }

  .whyChooseUsIcon {
    font-size: 44px !important;
  }
}
