@import "../color";
@import "../mediaquery";

.introContainer {
  padding: 16px !important;
  grid-gap: 16px !important;
}

.introCarousel img {
  height: 100%;
}

.introText {
  p {
    padding-top: 16px !important;
  }
}

.introDesc{
  

  p{
    margin-top:8px !important
  }

  .introReadMore{
    margin-top:8px !important;
  }
}

@media (min-width: $sm) {
  .introContainer {
    padding: 24px !important;
  }

}
@media (min-width: $lg) {
  .introContainer {
    padding: 48px !important;
  }
  .introCarousel {
    padding: 0px 24px !important;
  }
}