// @import "../color";
// @import "../mediaquery";

// .ourTeams {
//   .slick-prev {
//     left: 10px;
//     width: 50px;
//     height: 50px;
//     z-index: 10;
//   }

//   .slick-next {
//     right: 10px;
//     width: 50px;
//     height: 50px;
//   }
//   .slick-prev::before,
//   .slick-next::before {
//     font-family: "slick";
//     font-size: 30px;
//     line-height: 1;
//     opacity: 0.75;
//     color: white;
//     -webkit-font-smoothing: antialiased;
//     -moz-osx-font-smoothing: grayscale;
//   }

//   .slick-slider {
//     width: 100%;
//   }

//   .slick-slider img {
//     width: 100%;
//     height: 100% !important;
//     z-index: 0;
//     object-fit: contain;
//   }

//   .slick-list {
//     width: 100%;
//     height: 100% !important;
//   }

//   .slick-slide {
//     width: 100%;
//     height: 100% !important;
//   }

//   .slick-slide div {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//   }

//   // .slick-dots {
//   //   bottom: 60px;
//   //   opacity: 1;
//   //   width: 150px;
//   //   left: calc(50% - 75px);
//   // }

//   .slick-dots {
//     bottom: -32px !important;
//     opacity: 1;
//     width: 100% !important;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     left: 0px !important;
//     // background-color:green !important;
//   }

//   .slick-dots li button:before {
//     font-size: 12px;
//   }

//   padding: 16px;

//   .ourTeamsTitle {
//     text-align: center;
//   }

//   .ourTeamsSlider {
//     margin-top: 8px;
//   }

//   .ourTeamsImageContainer {
//     // border:3px solid red !important;
//     padding: 36px !important;
//     display: flex;
//     flex-direction: column;
//     justify-content: center !important;
//     align-items: center !important;
//     transition: all 500ms;

//     position: relative;
//   }

//   .ourTeamsImageContainer:hover {
//     transform: scale(1.05);
//   }

//   .ourTeamsImageContainer h3 {
//     // background-color: lightgreen;
//     text-align: center;
//   }
//   .ourTeamsImageContainer p {
//     // background-color: maroon;
//     text-align: center;
//   }
// }

// .myTeamImgHolder {
//   position: relative;
// }

// .ourTeamHover {
//   display: none !important;
//   width: 100% !important;
//   height: 48px !important;
//   position: absolute;
//   bottom: 0px !important;
//   transition: opacity 500ms !important;
// }

// .myTeamImgHolder:hover .ourTeamHover {
//   display: flex !important;
//   background-color: white !important;
//   opacity: 0.9;
//   justify-content: center !important;
//   align-items: center !important;

//   .ourTeamIconContainter {
//     // background-color: #0066a6 !important;
//     width: 35%;
//     display: flex !important;
//     justify-content: space-evenly !important;
//     align-items: center !important;
//   }

//   .socialMediaLink {
//     // margin-right:10px !important;
//   }
// }

// .hoverSocialMediaIcon {
//   color: black !important;
//   transition: all 0.5s;
// }

// .hoverSocialMediaIcon:hover {
//   transform: scale(1.2);
//   color: #0066a6 !important;
// }

// .ourTeamsBioData {
//   background-color: $primary_color !important;
//   position: absolute;
//   bottom: -10px !important;
//   width:100% !important;

//   display: flex;
//   flex-direction: column !important;
// }

// @media (min-width: 600px) {
//   .ourTeams {
//     .slick-dots {
//       bottom: 60px;
//       opacity: 1;
//       width: 150px;
//       left: calc(50% - 75px);
//       display: none !important;
//     }

//     .ourTeamsSliderGrid {
//     }

//     .ourTeamsImageContainer {
//       padding-left: 8px !important;
//       padding-right: 8px !important;
//     }
//   }
// }

// @media (min-width: 1024px) {
//   .ourTeams {
//     padding: 64px 128px;
//   }

//   .ourTeamsTitle {
//   }

//   .slick-prev {
//     left: 40px !important;
//     width: 50px;
//     height: 50px;
//     z-index: 10;
//   }

//   .slick-next {
//     right: 40px !important;
//     width: 50px;
//     height: 50px;
//   }
// }

.meetOurTeam {
  padding: 32px $mob-padding !important;
  display: flex;
  flex-direction: column;

  .slick-dots {
    bottom: -7% !important;
    opacity: 1;
    width: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0px !important;
  }

  .meetOurTeamTitleHolder {
    padding: 0px 0px 16px 0px !important;
    text-align: center;
  }

  .meetOurTeamCardHolder {
    // background-color: green !important;
    // display: grid !important;
    // grid-gap: 16px !important;
    .myteamCard {
      padding: 0px 8px !important;

      .innerTeamHover {
        // border: 2px solid red !important;
        width: 100% !important;
        height: 100% !important;
        padding: 8px !important;

        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        grid-gap: 16px !important;
      }

      .myTeamImageHolder {
        padding: 16px 16px 0px 16px !important;
      }

      .myTeamBioHolder {
        background-color: $primary-color;
        padding: 8px !important;
        display: flex !important;
        flex-direction: column !important;
        justify-content: center !important;
        align-items: center !important;

        .myTeamName {
          color: white !important;
        }

        .myTeamDesignation {
          color: #e3ce3d !important;
        }
      }
    }
  }
}

@media (min-width: 600px) {
  .meetOurTeam {
    .slick-dots {
      bottom: -8% !important;
      // background-color: green !important;
    }
  }

  .myTeamBioHolder {
    padding: 4px !important;
  }
}

@media (min-width: 800px) {
  .meetOurTeam {
    .slick-dots {
      bottom: -8% !important;
    }
  }

  .myTeamBioHolder {
    padding: 4px !important;
  }
}

@media (min-width: 1024px) {
  .meetOurTeam {
    padding: $desk-padding !important;
    // background-color: lightblue !important;
    .slick-dots {
      bottom: -12% !important;
    }
  }

  .meetOurTeamCardHolder {
    padding: 0px $tab-padding !important;
  }

  .myteamCard {
    position: relative;
  }

  .myTeamBioHolder {
    padding: 0px !important;

    .myTeamName {
      padding: 0px !important;
      margin: 0px !important;
    }
  }

  .myTeamImageHolder {
    position: relative;
  }

  .ourTeamHover {
    display: none !important;
    width: 100% !important;
    height: 48px !important;
    position: absolute;
    bottom: 0px !important;
    transition: opacity 500ms !important;
    // border: 2px solid maroon !important;

    padding: 0px 17px !important;

    .innerTeamHover {
      // border: 2px dotted orange !important;
      background-color: white !important;
      display: grid !important;

      .ourTeamIconContainter {
        // border: 2px dotted green !important;
        width: 100% !important;
        height: 100% !important;
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        grid-gap: 8px !important;
      }

      .hoverSocialMediaIcon {
        color: black !important;
      }

      .hoverSocialMediaIcon:hover {
        // color:blue !important;
        scale:1.2;
      }
    }
  }

  .ourTeamIconContainter {
    // border: 2px solid orange !important;
  }

  .myteamCard:hover .ourTeamHover {
    display: flex !important;
    opacity: 0.9;
    justify-content: center !important;
    align-items: center !important;
    color: black !important;
    margin-left: -16px;
  }
}
