

.imagePreviewBoxAcc{
    display:flex !important;
    justify-content:center !important;
    align-items:center !important;
}


.landImage1{
    width:50px !important;
    height:35px !important;
}

.portImage1{
    width:35px !important;
    height:50px !important;
}

.evenImage1{
    width:35px !important;
    height: 35px !important; 
}