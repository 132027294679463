@import "../spacing";
@import "../color";

.getInTouch {
  padding: $mob-padding;
  // border: 2px solid red;

  .getInTouchTitle {
    // border: 2px dotted red;
    padding: 0px;
  }

  .contactInfo {
    padding: 16px 0px;
    grid-gap: 8px;
    // border: 2px dotted red;
  }

  .contactIcons {
    color: $primary-color;
  }
  .contactInfoMetaData {
  }

  .contactInfoMetaData p {
    font-weight: 400 !important;
  }
  .getInTouchFormHolder {
    padding: 16px !important;
    border: 2px solid #f2f2f2;
  }

  .getInTouchForm {
    width: 100% !important;
  }
  .getInTouchFormInput {
    padding: 0px !important;
    margin-top: 25px;
  }

  .getInTouchForm input {
    margin-top: 24px;
  }

  .getInTouchFormSubmitButton {
    margin: auto;
    margin-top: 24px;
    max-width: 200px !important;
  }
}

@media (min-width: 600px) {
  .getInTouchFormHolder {
    padding: 32px !important;
    border: 2px solid #f2f2f2;
  }

  .contactInfo {
    padding: 0px 0px !important;
  }

  .getInTouchForm {
    padding: 0px 16px !important;
  }
}

@media (min-width: 800px) {
  .getInTouch {
    padding: $tab-padding;
  }

  .getInTouchTitle {
    margin-bottom: 16px !important;
  }

  .contactInfo {
    padding: 16px 0px;
    grid-gap: 16px;
  }

  .getInTouchFormHolder {
    padding: 0px !important;
    border: 2px solid #f2f2f2;
  }
}
@media (min-width: 1024px) {
  .getInTouch {
    padding: $tab-padding $desk-padding;
  }

  .getInTouchTitle {
    margin-bottom: 24px !important;
  }

  .contactInfo {
    padding: 16px 0px;
    grid-gap: 16px;
  }
}
