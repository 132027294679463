// .AdminPanel {
//   display: grid;
//   grid-template-columns: 1fr 5fr;
// }

// .pageMenu {
//   background-color: $primary-color;
//   color: white;
//   max-height: 500px;
// }

// .MainPageTab {
//   color: white !important;

//   display: flex;
//   justify-content: flex-start;
// }

// .tab {
//   padding: 0px !important;

//   color: white !important;
//   font-size: 12px !important;

//   display: flex !important;
//   flex-direction: row !important;
//   justify-content: flex-start !important;
//   align-items: center !important;
//   padding-left: 24px !important;
// }

.adminHeader {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 75px;
  background-color: $primary-color;
  padding-right: 16px;
  padding-left: 16px;

  .adminLogo {
    width: 10%;
    height: 100%;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .adminLogo img {
    width: 100%;
    }

  .adminTitle {
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    // border: 2px solid red;
    margin-left: auto;
  }

  .adminProfile {
    width: 20%;
    margin-left: auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 16px;
  }
}

.adminPanelSection {
  display: flex;
}

.adminPanelMenuSection {
  background-color: $primary-color;
  color: white;

  width: 200px;
  height: auto;
}

.adminViewSection {
  width: 100%;
}

.websiteHomeMenu {
  // background-color: pink;
}

.tab {
  color: white !important;
}

.websiteAdminMenuII {
  color: white !important;
}

.websiteAdminMenuIITab {
  color: white !important;
  text-align: left !important;

  display: flex !important;
  align-items: flex-start !important;
}

.MuiTabs-flexContainer {
  padding-left: 0px !important;
  display: flex !important;
  justify-content: flex-start !important;
}

.profileMenuPopUp {
  border: 2px solid red !important;
  height: 300px !important;
  background-color: $primary-color !important;
}


.profileMenuItem {
  color: $primary-color !important;
}

.profileMenuIcon {
  color: $primary-color;
}
