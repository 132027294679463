.mainAccreditations {
  padding: $tab-padding $mob-padding;
  background-color: $primary_color;

  h5 {
    text-align: center !important;
    padding: $mob-padding;
    color: white !important;
  }

  img {
    width: 100% !important;
    // height:50px !important;
    // min-height: 75px !important;
    // max-height: 100px !important;
    // height: 100px !important;
  }

  .slick-dots {
    bottom: -10% !important;
  }
}

.mainAccreditationsImageContainer {
  display: flex;
  flex-direction: column;
  grid-gap: 16px !important;
}

@media (min-width: 640px) {
  .mainAccreditations {
    .slick-dots {
      bottom: -12% !important;
    }
  }

  .mainAccreditationsImageContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    grid-gap: 16px !important;

    display: grid;
    grid-template-columns: repeat(2, minmax(100px, 1fr));

    img {
      width: 100% !important;
    }
  }


  .accreditationsImage{
    transition: all 0.5s !important;
  }

  .accreditationsImage:hover{
    transform: scale(1.05) !important;    
  }
}

@media (min-width: 1024px) {
  .mainAccreditations {
    padding: $mob-padding $desk-padding $desk-padding $desk-padding;

    .slick-dots {
      // background-color: red !important;
      bottom:-30px !important;
      height:25px !important;
    }
  }

  .mainAccreditationsHeading {
    text-align: center !important;
  }

  .mainAccreditationsImageContainer {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));

    img {
      height: 100px !important;
    }
  }
}
