.universities {
  padding: $mob-padding;
  display: grid;
  grid-gap: 16px;
}

.universities img {
  width: 100%;
}

.universitiesTitle {
  display: flex;
  justify-content: center;
  align-items: center;
}

.graduateSectionTitle h3 {
  padding: 16px 0px;
}

.graduateCardSection {
  display: grid;
  grid-gap: 16px;
}

.graduateCard {
  border: 1px solid #ccc;
  padding: 16px;
  display: grid;
  grid-template-columns: 100%;

  grid-gap: 8px;

  transition: all 300ms;
}

.graduateCard:hover {
  -webkit-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.75);
}

.graduateImgContainer img {
  //   border: 2px solid red;
  padding: 8px 16px;
}

.graduateCollegeDescription {
  display: grid;
  grid-gap: 8px;

  .para-clamping {
    text-align: justify;
    display: -webkit-box;
    -webkit-line-clamp: 3 !important;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

@media (min-width: 600px) {
  .graduateCardSection {
    display: grid;
    grid-gap: 16px;
    grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: 1024px) {
  .universities {
    padding: $desk-padding;
    grid-gap: 24px;
  }

  .graduateCardSection {
    display: grid;
    grid-gap: 32px;
    grid-template-columns: repeat(4, 1fr);
  }

  .graduateCollegeDescription {
    grid-gap: 4px;

    // border: 2px dotted red;
  }

  .graduateCollegeTitle h3 {
    font-size: 20px;
  }
}
