@import "mediaquery";

//FONT FAMILY
$primary-fontfamily: "Oswald";
$secondary-fontfamily: "lato";

//FONT SIZING
$mob-p-size: 14px;
$tab-p-size: 15px; //14px
$desk-p-size: 16px; //16px

$mob-h1-size: 16px;
$tab-h1-size: 24px;
$desk-h1-size: 32px;

$mob-h3-size: 14px;
$tab-h3-size: 18px;
$desk-h3-size: 24px;

$mob-anchor-decoration: none;

//FONT HEIGHT
$mob-h1-lineheight: 1.2;
$tab-h1-lineheight: 1.2;
$desk-h1-lineheight: 1.2;

$mob-h3-lineheight: 20px;
$tab-h3-lineheight: 20px;
$desk-h3-lineheight: 1.2;

//FONT WEIGHT
$desk-p-fontweight: 300;

$mob-h1-fontweight: 500;
$desk-h1-fontweight: 400;

$mob-h3-fontweight: 400;
$desk-h3-fontweight: 400;

//LETTER SPACING
$letter-spacing: normal;

//GLOBAL STYLING
p {
  font-family: $secondary-fontfamily;
  font-size: $mob-p-size;
  font-weight: $desk-p-fontweight;
  word-break: break-word;
  line-height: 1.75;
  letter-spacing: normal;
}

h1 {
  font-family: $primary-fontfamily;
  font-size: $mob-h1-size;
  font-weight: $mob-h1-fontweight;
  line-height: $mob-h1-lineheight;
  padding: 10px 0px;
}

h3 {
  font-family: $primary-fontfamily;
  font-size: $mob-h3-size;
  font-weight: $mob-h3-fontweight;
  line-height: $mob-h3-lineheight;
  padding: 0px;
}

h5 {
  font-family: $primary-fontfamily;
  font-size: 18px !important;
  font-weight:18px !important;
}

li {
  font-family: $secondary-fontfamily;
  font-size: $mob-p-size;
  font-weight: $desk-p-fontweight;
  word-break: break-word;
  line-height: 1.5;
  letter-spacing: normal;
}

a {
  font-family: $secondary-fontfamily;
  text-decoration: none;
}

@media (min-width: $sm) {
  p {
    font-size: $tab-p-size;
    font-weight: $desk-p-fontweight;
    line-height: 1.75;
  }
  li {
    font-family: $secondary-fontfamily;
    word-break: break-word;
    letter-spacing: normal;

    font-size: $tab-p-size;
    font-weight: $desk-p-fontweight;
    line-height: 1.75;
  }

  h1 {
    font-size: $tab-h1-size;
    line-height: $desk-h1-lineheight;
    font-weight: $desk-h1-fontweight;
  }

  h3 {
    font-size: $tab-h3-size;
    line-height: $desk-h3-lineheight;
    font-weight: $desk-h3-fontweight;
    padding: 15px 0px;
  }
}
@media (min-width: $lg) {
  p {
    font-size: $desk-p-size;
    font-weight: $desk-p-fontweight;
    line-height: 1.75;
  }

  h1 {
    font-size: $desk-h1-size;
    line-height: $desk-h1-lineheight;
    font-weight: $desk-h1-fontweight;
  }

  h3 {
    font-size: $desk-h3-size;
    line-height: $desk-h3-lineheight;
    font-weight: $desk-h3-fontweight;
  }
}
