.portImagePreview {
  width: 50px !important;
  max-width: 70px !important;
  transition: all 500ms !important;
}

.portImagePreview:hover {
  transform: scale(3) !important;
}

.introCarouselTableHead {
  // border: 2px solid red;
}

.introCarouselTableHead th:nth-child(1) {
  width: 5% !important;
}
.introCarouselTableHead th:nth-child(2) {
  width: 25% !important;
}
.introCarouselTableHead th:nth-child(3) {
  width: auto !important;
}
.introCarouselTableHead th:nth-child(4) {
  width: 10% !important;
}
.introCarouselTableHead th:nth-child(5) {
  width: 20% !important;
}
