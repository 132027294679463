

.mainTestimony {
  background-color: #ebebeb !important;
  padding: $mob-padding 0px !important;

  .testimonyContainer {
    .slick-slide {
      padding: 32px 16px !important;
      // background-color: red !important;
    }

    .slick-dots {
      // background-color: pink !important;
      position: absolute !important;
      bottom: 0px !important;
    }
  }

  .mainTestimonyTitleHolder {
    text-align: center !important;
  }
}

.testimonyCard {
  background-color: #f6f6f6 !important;
  border-radius: 15px !important;

  display: grid !important;
  grid-template-columns: 1fr 1.5fr !important;

  box-shadow: 2px 2px 9px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 2px 2px 9px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 2px 2px 9px 0px rgba(0, 0, 0, 0.75);

  position: relative;

  img {
    width: 160px !important;
    position: absolute !important;
    left: 0px !important;
    bottom: 0px !important;
    height: 85% !important;
    // border: 2px solid red !important;
    border-radius: 0px 0px 0px 15px !important;
    padding: 0px !important;
  }

  .testimonyImageHolder {
    padding-top: 10px !important;

    display: flex !important;
    flex-direction: column !important;
    justify-content: flex-end !important;
    align-items: flex-end !important;
  }

  .testimonyContentHolder {
    // background-color: pink !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;

    .testimonyContainer1 {
      //   border: 2px dotted red !important;
      // background-color: lightgreen !important;
      width: 100% !important;
      height: 225px !important;
      padding: 8px !important;
      display: flex !important;
      flex-direction: column !important;
      justify-content: center !important;

      .testimonyPara {
        font-size: 14px !important;
        font-family: $secondary-fontfamily !important;
        text-align: justify !important;
      }

      .testimonyBioContainer {
        margin-top: 4px !important;
        display: flex !important;
        flex-direction: column !important;
        justify-content: flex-end !important;
        align-items: flex-end !important;
      }
    }
  }
}

@media (min-width: 600px) {
  .slick-slide {
    padding: $tab-padding !important;
  }
}

@media (min-width: 1024px) {
  .mainTestimony {
    padding: $mob-padding 0px !important;

    .mainTestimonyTitleHolder {
      text-align: center !important;
      //   border:2px solid red !important;
      padding: 16px !important;
    }
  }
  .slick-slide {
    // border: 2px dotted red !important;
  }

  .testimonyContainer {
    padding: 0px $desk-padding !important;
    // border:2px dotted purple !important;
  }
}
