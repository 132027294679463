.serviceTableHead {
//   border: 2px solid red;
}

.serviceTableHead th:nth-child(1) {
  width: 5%;
}
.serviceTableHead th:nth-child(2) {
  width: 15%;
}
.serviceTableHead th:nth-child(3) {
  width: 20%;
}
.serviceTableHead th:nth-child(4) {
  width: auto;
}
.serviceTableHead th:nth-child(5) {
  width: 10%;
}
.serviceTableHead th:nth-child(6) {
  width: 20%;
}
