.detailsImageSection img {
  width: 100%;
  height: 20vh;
}

.countryDetail {
  padding: 8px 16px;
}

.detailCountryFacts {
  padding: 8px 16px;
}

.detailCountryFactLists ol {
  padding-left: 32px;
}

.detailRemoteStudy {
  padding: 8px 16px 16px 16px;
}

@media (min-width: 600px) {
  .detailsImageSection img {
    width: 100%;
    height: 50vh;
  }
}

@media (min-width: 800px) {
  .detailsImageSection img {
    width: 100%;
    height: 20vh;
  }

  .countryDetail {
    padding: 16px 24px;
  }

  .detailCountryFacts {
    padding: 16px 24px;
  }

  .detailRemoteStudy {
    padding: 16px 24px;
  }
}
@media (min-width: 1024px) {
  .detailsImageSection img {
    width: 100%;
    height: 60vh;
  }

  .countryDetail {
    padding: 16px 64px !important;
  }

  .detailCountryFacts {
    padding: 16px 64px !important;
  }

  .detailRemoteStudy {
    padding: 16px 64px 64px 64px !important;
  }
}

// .detailsImageSection

// .countryDetail

// .detailCountryFacts

// .detailRemoteStudy
