.aboutUsPage {
  grid-gap: 24px;

  .aboutUsIntro {
    padding: $mob-padding;
  }

  .aboutUsIntroImage {
    justify-content: flex-start;
    align-items: center;
  }

  .aboutUsIntroImage img {
    width: 100%;
  }

  .aboutUsIntroDescription {
    grid-gap: 8px;
  }
}

// .aboutUsPage {
//   padding: $tab-padding;
// }

.testimonyAndChecklist {
  grid-gap: 24px;
  padding: 16px;

  //   border: 2px dotted red !important;
}

.aboutUsTestimony {
  background-color: #0066a6;
  padding: 48px 16px;
  grid-gap: 8px;

  border-radius: 5px;
  color: white;
  text-align: justify;

  position: relative;
}

.aboutUsQuoteIcon {
  position: absolute;
  color: white;
  left: 5%;
  top: 8%;
  font-size: 35px;
}

.aboutUsCheckList {
  border-radius: 5px;
  padding: 32px 16px;
  grid-gap: 16px;
  border: 1px solid #a7a5a58f;
}

.checkListIcon {
  color: #0066a6;
}

.messageFromDirectorSection {
  padding:0px $mob-padding;

    // border: 2px dotted purple;

  .messageFromDirectorImageSection {
    // border: 2px solid red;
    position: relative;
  }

  .messageFromDirectorTitleS {
    position: absolute;
    top: 5%;
    right: 5%;
  }
  .messageFromDirectorTitleM {
    // display: none;
    display: flex !important;
    justify-content: center;
    align-items: center;
  }

  img {
    width: 50%;
  }
}

.aboutUsTeam{
  margin-bottom:24px;
}

.directorText{
  margin-top:12px !important; 
  // border:2px solid red !important;
}

.directorTextPara{
  margin-bottom:8px !important;
}



@media (min-width: 600px) {
  .aboutUsPage {
    .aboutUsIntro {
      padding: $tab-padding;
    }
  }

  .testimonyAndChecklist {
    padding: $tab-padding;
  }

  .messageFromDirectorImageSection {
    padding: $tab-padding;
    // border: 2px solid green !important;
    height: auto !important;

    img {
      width: 25%;
    }
  }

  .messageFromDirectorText {
    display:flex;
    flex-direction: row;

  }
  .directorText{
    padding:8px 8px 0px 8px;
  }
}

@media (min-width: 800px) {
  .aboutUsIntro {
    justify-content: space-between;
  }

  .aboutUsIntro,
  .testimonyAndChecklist {
    padding: $tab-padding !important;
  }

  .aboutUsIntroImage {
    padding: 16px;
  }
  .aboutUsIntroDescription {
    padding: 16px;
  }

  .messageFromDirectorImageSection {
    // padding: $tab-padding;
    padding: 0px !important;
    // border: 2px solid green !important;
    height: auto !important;

    .messageFromDirectorTitleS {
      display: none;
    }

    img {
      width: 100%;
    }
  }

  .messageFromDirectorTitleM {
    display: flex !important;
    justify-content: center;
    align-items: center;
    // border: 2px solid purple;
  }

  .messageFromDirectorText {
    padding: 0px 0px 16px 32px;
    text-align: justify;
  }
}

@media (min-width: 1024px) {
  //   .aboutUsPage {
  //     padding: $desk-padding;
  //   }

  .aboutUsIntro {
    padding: $desk-padding !important;
  }

  .testimonyAndChecklist {
    padding: 0px $desk-padding !important;
  }

  .aboutUsTestimony {
    padding: 56px !important;
    // background-color: pink !important;
  }

  .testimonyAndChecklist {
    // border: 2px dotted blue;
  }

  .aboutUsIntroDescription {
    padding: 0px 16px 0px 32px;
  }

  .messageFromDirectorImageSection {
    padding: 0px !important;
    height: auto !important;

    .messageFromDirectorTitleS {
      display: none;
    }

    img {
      width: 75%;
    }
  }

  .aboutUsTeam{
    margin-bottom:0px !important;
  }

  .directorText{
    padding:0px 16px 0px 32px;
  }

  .messageFromDirectorText {
    padding: 0px 0px 16px 48px;
    text-align: justify;
  }
}
