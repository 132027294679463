@import "../color";
@import "../typography";
@import "../mediaquery";
@import "../spacing";

.header {
  background-color: $primary_color !important;
  // box-shadow: 1.5px 1.5px 7px 0px $next_color !important;
  border-bottom: 2px solid $next_color !important;
}

.header img {
  width: 50px;
}

.headerSocialMediaContainer {
  width: 200px !important;
  display: flex !important;
  justify-content: flex-end !important;
  grid-gap: 3px !important;
}

.headerSocialAnc {
  border-radius: 50% !important;
  width: 35px !important;
  height: 35px !important;

  text-align: center !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  background-color: black !important;
}

.headerSocial {
}

.headerSocialAnc:hover {
  background-color: white !important;
}

.headerSocialAnc1 {
  // background-color: #1773ea !important;
}

.headerSocialAnc2 {
  // background-color: #d62d82 !important;
}

.headerSocialAnc1:hover {
  background-color: #1773ea !important;
}
.headerSocialAnc2:hover {
  background-color: #d62d82 !important;
}

.headerSocialAnc1:hover .headerSocial1 {
  // color: #1773ea !important;
}
.headerSocialAnc2:hover .headerSocial2 {
  // color: #d62d82 !important;
}

.loginPopOver {
  width: 250px;
  height: 200px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.credentialTextField {
  margin-top: 13px !important;
  padding-top: 10px !important;
}
.credentialButton {
  margin-top: 16px;
}

.listItemButton a {
  width: 100%;
  // border: 2px solid red !important;
  padding: 5px 0px !important;
  text-decoration: none;
  color: black;
  font-family: $secondary-fontfamily;
}

.navbarLinks {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: stretch;
  padding: 0px;
  list-style: none;

  .MuiListItemText-primary:hover {
    color: #ffcb04;
  }
}

.navbarLinks li {
  font-size: 14px;
  font-family: $secondary-fontfamily;
  color: white;
  height: 100%;
  align-self: center;

  display: flex;
  direction: column;
  justify-content: center;
  align-items: center;
}

.navbarLinks li:hover {
}

.navbarLinks li a {
  color: $primary_color;
  text-decoration: none;

  margin: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  display: block;
}

.navbarLinks li a:hover {
  color: #ffcb04;
}

.abroadStudyTrigger {
  position: relative;
}

.abroadStudyTrigger:hover .abroadStudyPopper {
  display: block;
}

.abroadStudyPopper {
  display: none;
  background-color: $primary-color !important;
  // color:white !important;
  // border: 2px solid red !important;
  border-radius: 5px;
  position: absolute !important;
  top: 25px;
  right: -50%;
  right: calc(50% - 100px) !important;
  // left:0% !important;

  width: 200px;
  min-width: 200px;
  max-width: 250px;
}

.abroadStudyPopper div {
  padding-right: 0px !important;
}


.countrySelection {
  position: relative !important;
  // background-color: blue !important;
  color: red !important;

  a {
    color: white !important;
    width: 100% !important;
  }
}

.countrySelection:hover {
  background-color: $next-color !important;
}

.studyLevelsContainer {
  position: absolute !important;
  top: 0px;
  right: 200px;

  width: 200px;
  background-color: $primary_color !important;
  display: none;
}

.countrySelection:hover .studyLevelsContainer {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
}

.studyLevelsContainer .studyLevelLists {
  display: flex;
  flex-direction: column;
}

.studyLevelsContainer a:hover {
  color: white !important;
}

.studyLevelsContainer .countrySelection a:hover {
  background-color: red !important;
}

.countryStudyLevelDrop {
  .MuiTypography-body1:hover {
    color: white !important;
  }
}

// .avatarAndLink {
//   display: flex;
//   justify-content: flex-start;
//   align-items: center;
// }

.collapseListContainer {
  // background-color: pink !important;
  display: flex;
  justify-content: flex-start !important;
  align-items: center !important;
}

.collapseIcon {
  margin-left: auto;
}

.mobileLogoContainer {
  // border: 2px solid blue !important;
}

@media (min-width: 1024px) {
  .header {
    padding: 0px 40px !important;
    height: 100px !important;
    background-color: $bg_color !important;
  }

  .largerHeader {
    height: 100% !important;

    img {
      height: 100% !important;
      width: 250px !important;
    }
  }

  .navbarLinksContainer {
    padding-bottom: 16px;
  }

  .navbarLinks {
    // border:2px solid red !important;
    padding: 0px 0px 0px 75px !important;
    display: flex !important;
    justify-content: space-between !important;
  }
}
