


.serviceDetail {
  // background-color: pink;
  padding: 0px $mob-padding;


  h1 {
    padding: 16px 0px;
  }
}

@media (min-width: 600px) {
  .serviceDetail {
    // background-color: pink;
    padding: $mob-padding $mob-padding * 3 ;

    h1 {
      padding: 16px 0px;
    }
  }
}

@media (min-width: 1024px) {
  .serviceDetail {
    padding: $tab-padding $desk-padding * 4;
    h1 {
      padding: $tab-padding 0px;
    }
  }
}