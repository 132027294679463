.myTeamsTable {
  //   border: 2px solid red;
}

.myTeamsTableHead th:nth-child(1) {
  width: 5%;
}
.myTeamsTableHead th:nth-child(2) {
  width: 20%;
}
.myTeamsTableHead th:nth-child(3) {
  width: 15%;
}
.myTeamsTableHead th:nth-child(4) {
  width: 15%;
}
.myTeamsTableHead th:nth-child(5) {
  width: 30%;
}
.myTeamsTableHead th:nth-child(6) {
  width: 5%;
}
.myTeamsTableHead th:nth-child(7) {
  width: 10%;
}

.teamSocialMedias {
  font-size: 14px !important;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.addNewTeamModal {
  overflow-y: scroll;
}

.addNewTeamModalPopUp {
  background-color: white !important;
  width: 400px;

  position: absolute;
  top: 5%;
  left: calc(50% - 200px);
}

.modalContent {
  width: 100% !important;
  padding: 16px !important;
  border: 2px dotted red !important;
}

.modalHeader {
  background-color: $primary-color;
  color: white;
  text-align: center;
}

.updateImagePreview {
  img {
    height: 100%;
  }
}

.modalContainer {
  padding: 24px !important;
}

.modalContent {
  border: 1px solid #cccccc !important;
  display: flex !important;
  flex-direction: column !important;
  grid-gap: 16px;
}

.myTeamRow {
  padding: 0px !important;
}

.myTeamImageContainer {
  // border: 1px solid yellow !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;

  img {
    width: 25% !important;
    transition: all 500ms;
  }

  img:hover {
    transform: scale(5);
  }
}

.textFieldContainer {
  width: 100% !important;
  display: flex !important;
  flex-direction: column !important;
  grid-gap: 16px !important;

  input {
    width: 100%;
  }
}

.modalContent input {
  width: 100%;
  height: auto;
  border: none;
}

.inputField {
  border: none !important;
}

.submitContainer {
  padding: 16px 0px !important;
  display: flex;
  justify-content: space-evenly;
}
