@import "../spacing";


.eventsAndNewsTitleHolder {
  background-color: $primary-color;
  color: white !important;
  text-align: center !important;
  padding: 16px 0px !important;
}

.testimonyAndEvents {
  padding: $mob-padding;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  grid-gap: 16px !important;


  h1,
  h3 {
    color: $primary-color;
  }

  .slick-arrow {
    display: none !important;
  }

  .events {
    // background-color: pink;
    width: 100%;
    margin-top:16px !important;
  }

  .eventsTitle {
    // border: 2px solid black;
    padding: 8px 0px;

    h1 {
      color: black;
    }
  }

  .eventsContainer {
    // border: 2px solid red;
  }

  .eventsCardLink {
    color: black;
  }

  .eventsCardLink:hover .eventCardDivider {
    color: red;
  }

  .eventsCard {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    padding: 8px 0px 0px 0px;

    transition: all 500ms;
  }

  .eventsCard:hover {
    transform: scale(1.05);
    .eventCardDivider {
      background-color: red !important;
    }
    .eventCardDivider:hover {
      color: red !important;
      background-color: red !important;
    }
  }

  .eventsDate {
    width: 15%;
    padding: 8px 0px 0px 0px;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    grid-gap: 8px;

    // background-color: pink;
  }

  .eventsDay h1 {
    font-size: 24px;
    padding: 8px 16px 8px 16px !important;
    // background-color: lightgreen;
  }

  .eventsMonth h3 {
    font-size: 16px;

    padding: 0px 0px 8px 0px;
  }

  .eventsDetail {
    width: 85%;
    padding: 8px 0px 8px 16px;
    display: flex;
    flex-direction: column;
  }

  .eventsName {
    padding: 0px 0px 0px 0px;

    h1 {
      font-size: 16px;
      font-weight: 400;
    }
  }

  .eventsTimingVenue {
    display: flex;
    flex-direction: column;
    grid-gap: 4px;
  }

  .eventsMetaData {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .eventsIcon {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .eventsTime {
    display: flex;
    align-items: center;
    justify-content: center;
    // border: 3px solid black;
    padding: 0px 0px 0px 6px;
  }

  .eventsSeeMore {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
  }

  .testimonials {
    // background-color: pink !important;
    // border: 1px solid grey;
    padding: 8px 0px !important;
    border-radius: 10px;
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    grid-gap: 8px !important;
  }

  .testimonialsContainer {
    display: flex !important;
    flex-direction: column !important;
    grid-gap: 16px !important;
  }

  .testimonialsTitle {
    // border: 2px dotted red;
    background-color: white;
    padding: 8px 0px;
    h1 {
      color: black;
    }
  }

  .testimonialReadMoreContainer {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .testimonialsSlidesHolder {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
      rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
    border: 1px solid transparent;

    position: relative;
  }

  .testimonialsCard {
    background-color: #f2f2f2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 16px;
  }

  .testimonialsProfile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .testimonialsProfileImage {
    width: 100px;
    height: 100px;
    border-radius: 50%;
  }

  .testimonialsProfileImage img {
    width: 100%;
    border-radius: 50%;
  }

  .testimonialsProfileDetails {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .testimonialsQuoteCard {
    padding: 16px 0px;
    text-align: justify;
  }

  .quoteIcon {
    color: $primary-color;
    position: absolute;
    top: 20px;
    left: 20px;

    font-size: 50px !important;
  }
}

@media (min-width: 600px) {
  .testimonyAndEvents {
    padding: $tab-padding;
  }

  h1 {
    margin: 0px;
    padding: 0px;
  }
  h3 {
    margin: 0px;
    padding: 0px;
  }

  .slick-dots {
    bottom: 10px;
    //   background-color: rgba(255, 255, 255, 0.219);
    opacity: 1;
    width: 150px;
    left: calc(50% - 75px);
  }

  .testimonials {
    // background-color: lightblue !important;
    display: flex !important;
    flex-direction: column-reverse !important;

    .testimonialReadMoreContainer {
      display: flex !important;
      flex-direction: row !important;
      justify-content: center !important;
      align-items: center !important;
    }

    .testimonialsContainer {
      display: grid !important;
      grid-template-columns: 1fr 1fr !important;
    }
  }

  .testimonialsProfile {
    display: flex;
    flex-direction: row !important;
    justify-content: flex-start !important;
    align-items: center;
    padding: 0px 16px !important;
  }

  .testimonialsProfileImage {
    // background-color: pink;
  }

  .testimonialsProfileDetails {
    margin-left: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    align-items: flex-start !important;
  }

  .readMoreHolder {
    display: flex !important;
    justify-content: flex-end !important;
    margin-top: 8px !important;
  }

  .eventsDetail {
    width: 85%;
    padding: 8px 0px 8px 16px;
    display: flex;
    flex-direction: column;
  }

  .eventsName {
    padding: 0px 0px 0px 0px;

    h1 {
      font-size: 20px !important;
      font-weight: 400;
    }
  }

  .eventsDay h1 {
    padding: 8px 16px 8px 16px !important;
    // background-color: lightgreen;
  }

  .eventsMonth h3 {
    padding: 0px 0px 8px 0px;
    // background-color: pink;
  }
}

@media (min-width: 800px) {
  .testimonyAndEvents {
    padding: $tab-padding;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;


    display: grid !important;
    grid-template-columns: 1fr 4fr !important;
  }

  .events {
    // background-color: lightblue !important;
  }
  .testimonials {
    flex-direction: row !important;
    flex-wrap: wrap;

    .testimonialReadMoreContainer {
      width: 100% !important;
      display: flex !important;
      justify-content: flex-end !important;
      align-content: center !important;
    }

    .testimonialsContainer {
      display: grid !important;
      grid-template-columns: repeat(2, 1fr) !important;
      grid-gap: 16px !important;
    }
  }

  .testimonialsCard {
    padding: 64px 32px !important;
  }

  .eventsDetail {
    width: 85%;
    padding: 8px 0px 8px 32px !important;
    display: flex;
    flex-direction: column;
  }
}

@media (min-width: 1024px) {
  .testimonyAndEvents {
    padding: 0px $desk-padding;
    display: grid !important;
    grid-template-columns: 1fr 5fr !important;
  }
  .events {
    // width: 25% !important;
  }

  .eventsCard {
    padding: 0px 0px 16px 0px !important;
  }

  .eventsDate {
    // background-color: lightblue;
    width: 20%;
    display: flex;
    flex-direction: column !important;
    justify-content: flex-start !important;
    align-items: center !important;
  }

  .eventsDay {
    // background-color: palegreen;
  }

  .eventsDay h1 {
    padding: 0px !important;
  }
  .eventsMonth {
    // background-color: aquamarine;
    display: flex;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    padding: 0px !important;
    margin: 0px !important;
  }
  .eventsMonth h3 {
    padding: 0px 0px 8px 0px;
  }

  .eventsDetail {
    width: 85%;
    padding: 0px 0px 0px 24px !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin: 0px !important;
  }

  .eventsTimingVenue {
    // border:2px dotted purple;
  }

  .testimonials {
    .testimonialsContainer {
      display: grid !important;
      grid-template-columns: repeat(4, 1fr) !important;
      grid-gap: 16px !important;
    }
  }

  // .testimonialsContainer {
  //   display: grid !important;
  //   grid-template-columns: repeat(4, 1fr);
  //   grid-gap: 16px !important;
  // }

  .testimonialsCard {
    padding: 64px !important;
  }
}
