.footer {
  padding: $mob-padding;
  background-color: $primary-color;
  background-color: $next-color !important;

  display: grid !important;
  grid-template-columns: 1fr !important;

  a,
  h3 {
    color: white;
  }

  a {
    font-family: $secondary-fontfamily;
    font-size: $mob-p-size;
    text-decoration: $mob-anchor-decoration;
  }

  h1 {
    font-family: $secondary-fontfamily;
    color: white;
  }

  .footerContacts {
    display: flex;
  }

  .footerContactCard {
    display: flex;
  }

  .footerContactsLogo {
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 100%;
      height: 50%;
    }
  }

  .footerContactsContainer {
    width: 60%;
    padding: 0px 0px 0px 8px;

    .footerContactTitle {
      padding: 8px 0px;
      h3 {
        font-family: $secondary-fontfamily;
      }
    }
    .footerContactContainer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      grid-gap: 4px;
      color: white;

      .footerContactCard {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .footerContactIconHolder {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .footerContactMetaData {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0px 0px 0px 8px;
        }
      }
    }

    .socialMediaContainerFooter {
      display: flex;
      justify-content: flex-start;
      grid-gap: 4px;
      color: white;

      .socialMediaLink {
        margin-right: 0px;
        background-color: white !important;
        // padding: 10px !important;
        border-radius: 50% !important;
        width: 35px !important;
        height: 35px !important;
        max-width: 35px !important;
        max-height: 35px !important;
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
      }
      .socialMediaLink:hover {
        transition: all 300ms !important;
        .fab {
          color: white !important;
        }
      }
      .socialMediaLink:visited {
        background-color: white !important;
        .fab {
          color: transparent !important;
        }
      }

      .fa-facebook-f,
      .fa-twitter,
      .fa-youtube,
      .fa-instagram {
        color: $primary-color !important;
      }

      .socialLinkFacebook:hover {
        background-color: #1773ea !important;
      }
      .socialLinkTwitter:hover {
        background-color: #1c99e6 !important;
      }
      .socialLinkYoutube:hover {
        background-color: #f20000 !important;
      }
      .socialLinkInstagram:hover {
        background-color: #d62d82 !important;
      }
    }
  }

  .footerUL {
    list-style-position: inside;
  }

  .footerLinksContainer {
    margin-top: 8px;
    .footerLinksTitle {
      padding: 8px 0px;

      h1 {
        color: #fb9f00;
      }
    }

    .footerLinks {
      padding: 0px 16px;
      grid-gap: 8px;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;

      .footerUL {
        li {
          color: white !important;
          a {
            font-size: 14px !important;
            font-weight: 400 !important;
            font-family: $secondary-fontfamily;
          }
        }
      }
    }
  }

  .socialMediaContainerFooter {
    padding: 8px 0px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 8px;
  }
  .footerCountries {
    height: auto !important;
    max-height: 200px !important;
    width: 100% !important;
    grid-gap: 10px;
    display: flex !important;
    align-items: flex-start !important;
    flex-direction: column !important;
    flex-wrap: wrap !important;
  }
  .footerCountryLink {
    display: inline !important;
  }

  .footerCountryCol {
    // background-color: pink !important;
    overflow: hidden !important;
    width: 100% !important;
    display: grid !important;
    grid-auto-flow: column !important;
    grid-template-columns: 1fr 1fr !important;
    grid-template-rows: repeat(7, 1fr) !important;

    a {
      width: 100% !important;
    }
  }
}

@media (min-width: 600px) {
  //
  //     display: flex;
  //     flex-wrap: wrap;
  //     justify-content: space-between;

  .footer {
    h1,
    h3 {
      font-size: 16px !important;
    }
  }

  .footerContacts {
    display: grid !important;
    grid-template-columns: 1fr 2fr !important;
    grid-gap: 24px !important;
    .footerContactsLogo {
      width: 100% !important;
    }

    .footerContactsContainer {
      width: 100% !important;
    }
  }

  //     .footerContactMetaData p {
  //       font-size: 12px;
  //     }

  //     .footerContacts {
  //       width: 30%;
  //       display: flex;
  //       flex-direction: column;
  //       justify-content: center;
  //       align-items: flex-start;
  //       padding: 0px !important;

  //       .footerContactsLogo {
  //         width: 50%;
  //       }

  //       .footerContactsContainer {
  //         width: 100%;
  //         margin: 0px !important;
  //         padding: 0px !important;
  //       }
  //     }

  //     .footerLinksContainer {
  //       width: 20%;
  //       border:2px solid maroon !important;
  //     }

  //     .footerContactContainer {
  //       justify-content: stretch;
  //     }

  //     .footerLinks {
  //       padding: 0px !important;
  //       grid-gap: 16px !important;
  //       display: flex;
  //       justify-content: center;
  //       align-items: flex-start;
  //       flex-direction: column;

  //       a {
  //         display: inline-block;
  //       }
  //     }
  //   }
}

@media (min-width: 800px) {
  .footer {
    padding: $tab-padding;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    display: grid !important;
    grid-template-columns: 1fr 3.5fr !important;
    grid-gap: 10px !important;

    .footerContacts {
      display: grid !important;
      grid-template-columns: 1fr !important;
      grid-gap: 0px !important;
      .footerContactsLogo {
        width: 100% !important;
        display: flex !important;
        justify-content: flex-start !important;
        align-items: center !important;

        img {
          max-width: 200px !important;
        }
      }

      .footerContactsContainer {
        width: 100% !important;
      }
    }

    .footerContents {
      width: 100% !important;
      display: grid !important;
      grid-template-columns: 0.75fr 1fr 1.5fr;
      // background-color: pink !important;
    }

    .footerLinksContainer {
      // width: 20%;
      // border: 2px solid red !important;
    }

    .footerContactContainer {
      justify-content: stretch;
    }

    .footerLinks {
      padding: 0px !important;
      grid-gap: 16px !important;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;

      a {
        display: inline-block;
      }
    }

    .footerLinks {
      padding: 0px !important;
      grid-gap: 16px !important;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;

      a {
        display: inline-block;
      }
    }

    .footerCountryCol {
      // background-color: pink !important;
      width: 100% !important;
      display: grid !important;
      grid-auto-flow: column !important;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: repeat(7, 1fr);

      a {
        width: 100% !important;
      }
    }
  }

  .footerContacts {
    width: 20% !important;
    display: flex;
    justify-content: flex-start !important;
    align-items: flex-start !important;
  }

  .footerContactMetaData {
    p {
      font-size: 14px !important;
    }
  }

  .footerLinksContainer {
    // width: 20% !important;
    // border:2px solid purple;
    .footerLinks {
      display: flex;
      justify-content: center !important;
      align-items: flex-start !important;
      a {
        // text-align: center;
      }
    }
  }

  .footerLinksContainer:nth-child(2) {
    // width: 35% !important;
  }
}

@media (min-width: 1024px) {
  .footer {
    padding: 16px $desk-padding;

    display: grid !important;
    grid-template-columns: 1fr 4fr !important;

    grid-gap: 48px !important;

    // .footerContacts {
    //   display: flex;
    //   justify-content: center;
    //   align-items: flex-start;

    //   img {
    //     width: 75%;
    //   }
    // }

    .footerContacts {
      width: 100% !important;
      // background-color: lightblue !important;
      display: grid !important;
      grid-template-columns: 1fr !important;
      grid-gap: 0px !important;
      .footerContactsLogo {
        width: 100% !important;
      }

      .footerContactsContainer {
        width: 100% !important;
      }
    }

    .socialMediaContainerFooter {
      grid-gap: 8px !important;
    }

    .footerContents {
      // grid-template-columns: 1fr 1fr 1.25fr !important;
      grid-template-columns: 0.75fr 1fr 1fr 1.5fr !important;
    }

    .footerLinksContainer {
      // width: 25% !important;
    }

    .footerLinksContainer:nth-child(2) {
      // width: 25% !important;
    }
  }
}
