@import "../color";
@import "../mediaquery";

.slick-prev {
  left: 10px;
  width: 50px;
  height: 50px;
  z-index: 10;
}

.slick-next {
  right: 10px;
  width: 50px;
  height: 50px;
}

.slick-prev::before,
.slick-next::before {
  font-family: "slick";
  font-size: 30px;
  line-height: 1;
  opacity: 0.75;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-dots {
  bottom: 8px !important;
  opacity: 1;
  width: 100% !important;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0px !important;

}

.slick-slider {
  width: 100%;
}

.slick-slider img {
  width: 100%;
  height: 100% !important;
  z-index: 0;
  object-fit: contain;
}

.slick-list {
  width: 100%;
  height: 100% !important;
}

.slick-slide {
  width: 100%;
  height: 100% !important;
}

.slick-dots li button:before {
  font-size: 12px;
}

@media (min-width: 600px) {
  .slick-prev {
    left: 25px;
    width: 75px;
    height: 75px;
    z-index: 10;
  }

  .slick-next {
    right: 25px;
    width: 75px;
    height: 75px;
  }

  .slick-prev::before,
  .slick-next::before {
    font-size: 40px !important;
  }


  .slick-dots {
    bottom: 16px !important;
    // background-color: red;
    opacity: 1;
    width: 100% !important;
  
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0px !important;
  
    // left: calc(50% - 75px);
  }
}

@media (min-width: 1024px) {
  .slick-prev::before,
  .slick-next::before {
    font-size: 50px !important;
    opacity: 0.5;
  }

  .slick-dots li button:before {
    font-size: 15px;
  }

  .slick-dots {
    bottom: 30px;
    opacity: 1;
    width: 100% !important;

  }
}
