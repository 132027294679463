@import "../spacing";

.newsAccreditations {
  padding: $mob-padding;

  .accreditationsSummary {
    background-color: $primary_color;
    color: white;

    .MuiSvgIcon-root {
      color: white !important;
    }
  }
}

.newsCardHolder {
  display: flex;
  flex-direction: column !important;
  grid-gap: 8px !important;
}

.newsCard {
  border: 1px solid #ccc;
  transition: all 300ms;

  img {
    width: 100% !important;
  }
}

.newsCard:hover {
  -webkit-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.75);
  transform: scale(1.03);
}

.newsCardDescription {
  padding: 8px;
}

.newsContainer {
  display: flex;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@media (min-width: 600px) {
  .newsCardHolder {
    display: grid !important;
    grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: 1024px) {
  .newsAccreditations {
    padding: $desk-padding;
  }

  .newsCardHolder {
    display: grid !important;
    padding: 0px !important;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
  }
  .newsCard {
    padding: 0px !important;
  }
  .newsCardDescription {
    padding: 8px 16px;
  }
}
