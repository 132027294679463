.threePara {
  text-align: justify;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.onePara {
  text-align: justify;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.ourServices {
  padding: $mob-padding 0px !important;
  display: grid !important;
  grid-template-columns: 1fr;
}

.ourServiceHeading {
  padding: 0px 16px !important;
}

.ourServiceContainer {
  display: grid !important;
  padding: 0px 16px !important;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)) !important;
  grid-auto-rows: minmax(200px, 1fr) !important;

  justify-content: flex-start;
  align-items: flex-start;
  grid-gap: 24px !important;
}

.ourServicesCards {
  padding: $mob-padding !important;
  display: grid !important;
  grid-template-columns: 1fr !important;

  grid-gap: 16px !important;

  align-items: stretch !important;
}

.ourServiceCard {
  display: flex;
  flex-direction: column;
  height: auto !important;
  padding: 0px 0px 16px 0px !important;
  transition: all 500ms !important;
}

.ourServiceCard:hover {
  scale: 1.025;
  box-shadow: 2px 2px 9px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 2px 2px 9px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 2px 2px 9px 0px rgba(0, 0, 0, 0.75);
}

.viewMoreServiceContent {
  transition: all 500ms !important;
}

.viewMoreServiceContent:hover {
  scale: 1.025;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  // box-shadow: none !important;
  // box-shadow: 2px 2px 9px 0px rgba(0, 0, 0, 0.75);
  // -webkit-box-shadow: 2px 2px 9px 0px rgba(0, 0, 0, 0.75);
  // -moz-box-shadow: 2px 2px 9px 0px rgba(0, 0, 0, 0.75);
}

.viewMoreText {
  transition: all 500ms !important;
}

.viewMoreText:hover {
  // scale: 1.025;
  scale: 1.25;
  color: maroon !important;
}

.ourServiceHeading {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.serviceHeading {
  font-size: 16px !important;
  color: $next-color !important;
  font-weight: 600 !important;
}

.ourServiceDescContainer {
  p {
    font-size: 14px !important;
  }
}

.ourServiceImageContainer {
  position: relative;
}

.viewOtherServices {
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;

  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
}

.viewOtherServices {
  // opacity:0.2;

  color: white !important;

  background: rgb(0, 0, 0);

  background: rgba(0, 0, 0, 0.6);

  p {
    font-size: 24px !important;
  }
}

.viewMoreServiceCard {
  height: 100% !important;
  display: grid !important;

  grid-template-columns: 1fr 1fr !important;

  .viewMoreServiceContent {
    display: grid !important;

    .viewMoreLink {
      display: flex !important;
      justify-content: center !important;
      align-items: center !important;
      color: black;
    }

    .viewMoreLink:hover {
      // box-shadow: 2px 2px 9px 0px rgba(0, 0, 0, 0.75);
      // -webkit-box-shadow: 2px 2px 9px 0px rgba(0, 0, 0, 0.75);
      // -moz-box-shadow: 2px 2px 9px 0px rgba(0, 0, 0, 0.75);
    }

    .viewMoreContents {
      width: 100% !important;
      display: flex !important;
      flex-direction: column !important;
      justify-content: center !important;
      align-items: center !important;

      .viewMoreText {
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        text-align: center !important;
        h3 {
          font-size: 35px !important;
          line-height: 40px !important;
        }
      }

      .viewMoreIcon {
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        text-align: center !important;
      }
      .addIcon {
        font-size: 75px !important;
      }
    }
  }

  .viewMoreServiceImage {
    position: relative !important;
    display: flex !important;
    justify-content: flex-end !important;
    align-items: center !important;

    img {
      width: 100% !important;
      height: 100% !important;
      // border:2px solid red !important;
      position: absolute !important;
      bottom: 0px !important;
      right: 0px !important;
    }
  }
}

.viewMoreServiceCard:hover {
  box-shadow: none !important;
  scale: 1 !important;
}

.serviceHeader {
  color: $next-color;
}

.serviceSeeMore {
  margin-top: 8px !important;
}

@media (min-width: 600px) {
  .ourServiceContainer {
    display: grid !important;
    grid-gap: 16px !important;
  }

  .ourServices {
    padding: $mob-padding !important;
  }

  .ourServicesCards {
    grid-template-columns: 1fr 1fr !important;
  }
}

@media (min-width: 800px) {
  .ourServices {
    padding: $mob-padding 0px !important;
  }

  .ourServiceContainer {
    padding: 0px $tab-padding !important;
    display: grid !important;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)) !important;
    grid-gap: 32px !important;
    // grid-template-columns: 1fr 1fr !important;
  }

  .ourServiceButtonContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 8px;
  }

  .serviceHeading,
  .ourServiceDescContainer,
  .ourServiceButtonContainer {
    padding: 0px 12px 0px 12px !important;
  }
}

@media (min-width: 1024px) {
  .ourServiceContainer {
    padding: 0px 48px !important;
    display: grid !important;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)) !important;
    grid-gap: 32px !important;
  }

  .ourServicesCards {
    grid-template-columns: 1fr 1fr 1fr !important;
    padding: 0px $desk-padding !important;
  }
}
