@import "../typography";

.tabPanelHeader {
  background-color: $primary-color;
  color: white;
  display: flex;
  justify-content: space-between !important;
  align-items: center !important;
  padding: 0px 16px !important;
}

.addButton {
  background-color: #94bd44 !important;
  color: white !important;
}

.addButton:hover {
  // color:yellow !important;
}

.CMSkeys {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
}

.tabPanelHeader h3 {
  display: inline;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  border: 1px solid #dddddd;
}
tbody {
}

td,
th {
  border-bottom: 1px solid #dddddd;
  width: 100px;
}

tr {
  text-align: center;
  height: 50px;
  transition: all 500ms;
  border-bottom: 2px solid #ffffff !important;
  background-color: #cce0ed !important;
}

tr:hover {
}

thead {
  height: 50px;
  background-color: #4c93c0;
  color: white !important;
}

.mainCarouselTableHead th:first-child {
  width: 5%;
}

.mainCarouselTableHead th:nth-child(2) {
  width: 15%;
}
.mainCarouselTableHead th:nth-child(3) {
  width: 25%;
}
.mainCarouselTableHead th:nth-child(4) {
  width: 25%;
}

.mainCarouselTableHead th:nth-child(5) {
  width: 10%;
}
.mainCarouselTableHead th:nth-child(6) {
  width: 10%;
}

.updateModal {
  overflow-y: scroll;
}

.imagePreviewContainer {
  .imagePreviewBox {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  img {
    width: 50%;
    transition: all 500ms;
  }

  img:hover {
    transform: scale(2.75);
  }
}

.formClass {
  border: 1px solid #cccccc !important;
  padding: 10px !important;
  width: 100% !important;

  display: flex;
  flex-direction: column;
  grid-gap: 24px;
}

.formClass input {
  height: auto;
  border: none;
}

.inputField {
  border: none !important;
}

.updateImagePreview {
  padding: 1%;

  img {
    width: 100%;
  }
}

.updateButtonContainer {
  display: flex;
  justify-content: space-evenly;
}
