.pnServices {
}

.ourServiceBannerContainer {
  background-color: $primary-color;
  padding: $mob-padding;
  grid-gap: 16px;
  color: white;

  h1,
  h3 {
    text-align: center;
  }

  button {
    color: white;
    border: none !important;
  }
}

.servicesGridHolder {
  padding: $mob-padding !important;

  h3 {
    // font-size: 16px;
  }

  .servicesGridHolderTitle {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: $mob-padding 0px;
  }
}

.serviceCardHolder {
  grid-gap: 24px;

  .serviceCard {
    border: 2px solid #f1f1f1;
    p {
      text-align: justify;
    }
  }
}

.serviceCardDesc {
  padding: $mob-padding !important;
  grid-gap: $mob-padding !important;

  h3 {
    font-family: "Lato";
  }

  .serviceDescButton {
    padding: 8px 0px;
  }

  .serviceDescLink {
    text-decoration: $mob-anchor-decoration !important;
    color: green;
    font-family: "Lato";
    font-size: 13px;
  }
}

@media (min-width: 600px) {
  .servicesGridHolder {
    padding: $tab-padding !important;
    .servicesGridHolderTitle {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0px !important;
      // background-color: pink;
    }
  }

  .serviceCardHolder {
    padding: 0px $tab-padding !important;
    display: flex;
    justify-content: space-between !important;
    align-items: center !important;

    .serviceCard {
      width: 47% !important;
      // padding:10px !important;
      // background-color:green !important;
    }
  }

  .serviceCardDesc {
    padding: 0px $mob-padding !important;
    grid-gap: 0px !important;

    .serviceDescLink {
      text-decoration: $mob-anchor-decoration !important;
      font-family: "Lato";
      font-size: 13px;
      padding: 16px 0px !important;
    }
  }
}

@media (min-width: 800px) {
  .ourServiceBannerContainer {
    padding: $desk-padding !important;
  }

  .serviceCardHolder {
    padding: 0px $tab-padding !important;
    grid-gap: 16px !important;
  }

 .serviceHeader, .ourServiceContent,.serviceSeeMore{
    padding-right:12px !important;
    padding-left:12px !important;
  }
}
@media (min-width: 1024px) {
  .ourServiceBannerContainer {
    padding: $desk-padding !important;
  }

  .serviceCardHolder {
    padding: $tab-padding !important;
    grid-gap: 24px !important;

    .serviceCard {
      width: 23% !important;

      h3 {
        font-size: 16px !important;
      }
    }
  }
}
