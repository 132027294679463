.pnNewsAndEvents {
  padding: 0px $mob-padding;

  a {
    font-family: "Lato";
    font-size: 14px;
    text-decoration: $mob-anchor-decoration;
  }

  .pnNewsAndEventsTitle {
    // border: 2px dotted purple;
  }

  .para-clamping {
    text-align: justify;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .pnNewsContainer {
    grid-gap: 16px;
  }

  .pnNewsCard:hover {
    -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.75);
    transform: scale(1.05);
  }

  .pnEventCard {
    transition: all 300ms;
  }

  .pnEventCard:hover {
    transform: scale(1.05);
  }

  .pnEventCardHolder {
    .pnEventCardLink {
      color: black;
    }
  }

  .pnNewsCard {
    border: 2px solid #f6f6f6;
    transition: all 500ms;
    .pnNewsDescription {
      padding: 0px 16px 16px 16px;
      //   grid-gap: 4px;

      .pnNewsPara {
        font-size: 14px;
        margin-top: 8px;
      }
    }
  }
}

.eventCardDivider {
  transition: all 300ms;
}

.pnEventCardLink:hover .eventCardDivider {
  background-color: $primary-color !important;
}

.pnNewsImage {
  padding: 0px 0px 16px 0px;
}

.pnNewsImage img {
  width: 100%;
  height: 100%;
  //   object-fit: cover;
}

.pnNewsSeeMore {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 0px;
}

.pnEventDetails {
  padding: 8px $mob-padding;
  grid-gap: 8px;
}

.pnEventBrief {
  grid-gap: 4px;
}

.pnEventDate {
  grid-gap: 8px;
}

.pnEventDay {
  h1 {
    padding: 0px !important;
    color: $primary-color;
  }
}
.pnEventMonth {
  h3 {
    color: $primary-color;
  }
}

.pnEventTitle {
  color: $primary-color;
}

.pnEventMetaData {
  padding-left: 8px;
}

@media (min-width: 600px) {
  .pnNewsAndEvents {
    padding: $mob-padding;
  }
  .para-clamping {
    text-align: justify;
    display: -webkit-box;
    -webkit-line-clamp: 3 !important;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .pnNewsImage {
    padding: 0px;
  }

  .pnNewsDescription {
    padding: 0px 16px !important;
  }

  .pnNewsDate {
    // border: 2px dotted blue;
  }

  .pnNewsTitle {
    // border: 2px dotted purple;
  }
  .pnNewsPara {
    margin-top: 0px;
    // border: 2px dotted red;
  }
  .pnNewsSeeMore {
    // border: 2px dotted green;
  }
}
@media (min-width: 800px) {
  .pnNewsAndEvents {
    padding: $mob-padding $tab-padding;
  }

  .pnNewsDescription {
    padding: 8px 16px !important;
  }

  .pnEventDetails {
    padding: 16px $mob-padding;
  }
}
@media (min-width: 1024px) {
  .pnNewsAndEvents {
    padding: $tab-padding $desk-padding;
  }
  .pnNewsSection {
    padding: 0px 32px 0px 0px;
    //   border:2px solid red;
  }

  .pnEventDetails {
    padding-left: 48px;
    // border:2px dotted purple;
  }
}
