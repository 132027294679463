@import "color";
@import "typography";
@import "mediaquery";
@import "spacing";
@import "./components/carousel";
@import "./components/introContainer";
@import "./components/recommendedPlaces";
@import "./components/whychooseus";
@import "./components/accreditations";
@import "./components/meetOurTeam";
@import "./components/testimonyAndEvents";
@import "./components/ourServices";
@import "./components/newsAccreditations";
@import "./components/_getInTouch";
@import "./components/testimony";
@import "./components/footer";

//component styling
@import "./components/topnavbar";
@import "./components/header";

//page navigation styling import
@import "./components/pageNavigation/pnServices";
@import "./components/pageNavigation/pnAboutUs";
@import "./components/pageNavigation/pnNewsAndEvents";
@import "./components/pageNavigation/pnDetail";
@import "./components/pageNavigation/universities";
@import "./components/pageNavigation/pnNews";
@import "./components/pageNavigation/pnServiceDetails";

@import "./admin/adminPanel";
@import "./admin/mainCarouselTable";
@import "./admin/introCarouselTable";
@import "./admin/myTeamsTable";
@import "./admin/testimonialTable";
@import "./admin/serviceTable";
@import "./admin/eventTable";
@import "./admin/studyAbroadTable";
@import "./admin/accreditationTable";
@import "./admin/adminForm.scss";

//spacing
@import "./spacing";

* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box !important;
}

html {
  scroll-padding-top: 65.8167px;
}

.navbarLinks {
  .navLink {
    // a {
    //   color: $primary_color !important;
    // }
    // color: red !important;
  }

  .navLink:hover {
    color: $next_color !important;
  }
}

.activeLink,
.studylink:hover {
  color: $next_color !important;
}

.activeLink {
  color: $next_color !important;
}

.disabled-link {
  pointer-events: none !important;
}

.studyLink:hover {
  color: $next-color !important;
}

//icon color
.fab {
  color: white;
  font-size: 20px;
}

//selected tab
.Mui-selected {
  color: #ffcb04 !important;
}

//TABLE CELL PARAGRAPH
.tableCellData {
  font-size: 14px;
  padding: 0px 8px;
}

.readMoreButton {
  color: white !important;
  background-color: $next_color !important;
  padding: 4px 16px !important;
  border: none !important;
  border-radius: 3px !important;
  // background-color: green !important;
  padding: 5px 16px !important;
}

.getMoreDetailsBtn {
  color: $bg_color;

  background-color: $next-color;
  padding: 4px 16px !important;
  border: none !important;
  border-radius: 3px !important;
}

.slick-slide {
  padding: 0 !important;
}

.navigateLink {
  color: $next-color;
}

.registrationBtn {
  background-color: $next-color !important;
}

//overrides


.MuiListItemButton-root:hover{
  background-color:$next-color !important;
}




.MuiTab-root:hover {
  background-color: $next-color !important;
}

.Mui-selected {
  // background-color:  #f2f2f2 !important;
  color:white !important;
  background: rgba(255, 255, 255, 0.338) !important;
}

.MuiTabs-indicator{
  background-color: transparent !important;
}

