@import "../color";
@import "../mediaquery";

.topNavbar {
  background-color: $primary_color;
  padding: 0px 24px;
  color: white;
  margin-top: 0px !important;

  button {
    font-size: 14px;
    background-color: #94bd44;
    font-weight: 600;
    text-transform: capitalize;
    color: white;
    border-radius: 0px;
    max-width: 250px;
  }
  .socialMediaContainer {
    margin-top: 10px;
  }

  .socialMediaLink {
    margin-right: 8px;
    background-color: white !important;
    // padding: 10px !important;
    border-radius: 50% !important;
    width: 35px !important;
    height: 35px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
  .socialMediaLink:hover {
    background-color: red !important;
    transition: all 300ms !important;
    .fab {
      color: white !important;
    }
  }

  .socialMediaLink:visited {
    background-color: white !important;
    .fab {
      color: transparent !important;
    }
  }

  .fa-facebook-f,
  .fa-twitter,
  .fa-youtube,
  .fa-instagram {
    color: $primary-color !important;
  }

  .socialLinkFacebook:hover {
    background-color: #1773ea !important;
  }
  .socialLinkTwitter:hover {
    background-color: #1c99e6 !important;
  }
  .socialLinkYoutube:hover {
    background-color: #f20000 !important;
  }
  .socialLinkInstagram:hover {
    background-color: #d62d82 !important;
  }
}

.topNavBarModalContainer {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 40px !important;
}

.topnavBarModal {
  border-radius: 7px;
  width: 300px !important;
  left: calc(50% - 150px) !important;

  .appRegistrationTitle {
    // background-color: pink !important;
    text-align: center !important;
    padding: 16px 0px !important;
    position: relative !important;

    .cancelModal {
      position: absolute !important;
      top: 0px !important;
      right: 0px !important;
    }

    .cancelModal:hover {
      cursor: pointer !important;
    }
  }
}

.topNavBarMetaData {
  background-color: $next_color;
  padding: 0px 16px !important;
}

.registrationBtn {
  background-color: red !important;
}

@media (min-width: $sm) {
  .socialMediaContainer {
    margin-top: 0px !important;
    padding: 0px;
  }

  .socialMediaLinks {
    padding: 0px !important;
  }

  .emailLink {
    margin-left: 20px;
  }
}

@media (min-width: $lg) {
  .topNavbar {
    padding: 0px 40px !important;
  }

  .topNavBarMetaData {
    padding: 0px 32px !important;
  }

  .socialMediaContainer {
    margin-top: 0px !important;
  }

  .registrationBtnContainer {
    display: flex;
  }

  .registrationBtn {
    height: 25px;
  }

  .emailLink {
    margin-left: 20px !important;
  }

  .contactAddress {
    margin-left: 5px;
  }

  .topnavBarModal {
    border-radius: 7px;
    width: 400px !important;
    left: calc(50% - 200px) !important;
  }
}
